// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-study-1-js": () => import("/opt/build/repo/src/pages/case-study-1.js" /* webpackChunkName: "component---src-pages-case-study-1-js" */),
  "component---src-pages-case-study-2-js": () => import("/opt/build/repo/src/pages/case-study-2.js" /* webpackChunkName: "component---src-pages-case-study-2-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-other-services-js": () => import("/opt/build/repo/src/pages/other-services.js" /* webpackChunkName: "component---src-pages-other-services-js" */),
  "component---src-pages-services-js": () => import("/opt/build/repo/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

